<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    autocomplete=off
  >
  <!-- <div> -->
    <v-container>
      <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
        Trazabilidad
      </div>

      <v-row no-gutters>
        <v-col cols="5" sm="4" md="3" lg="2" xl="2">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fechaFormateada"
                label="Fecha"
                @blur="fecha = parseDate(fechaFormateada)"                
                v-on="on"
                outlined
                dense
                append-icon="mdi-calendar"
                class="mr-1"
                readonly
               :rules="[v => !!v || 'La fecha es obligatoria']"
              ></v-text-field>
                <!-- @change="cargarArticulosTrazabilidad" -->
            </template>
            <v-date-picker v-model="fecha" no-title first-day-of-week = "1" @input="menu1 = false"></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="4" sm="2" md="2" lg="2" xl="2">  <!-- style="width: 150px" cols="auto"-->
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="hora"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="hora"
                label="Hora"
                append-icon="mdi-clock-outline"
                class="mr-2"
                outlined
                dense
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menu2"
              v-model="hora"
              full-width
              @click:minute="$refs.menu.save(hora)"
            ></v-time-picker>
          </v-menu>
        </v-col>

        <v-col cols="3" sm="2" md="2" lg="2" xl="1">
          <v-radio-group 
          class="mt-n2" 
          dense 
          v-model="TipoCreacion">
            <v-radio
              label="Artículo"
              value="Articulo"
            >
            </v-radio>
            <v-radio
              label="Tipo"
              value="Grupo"
              class="mt-n2 mr-4"
            >
            </v-radio>
          </v-radio-group>
        </v-col>

        <template v-if="TipoCreacion == 'Grupo'">
          <v-col cols="12" sm="7" md="6" lg="5" xl="4">
            <ArtCatFind class="mb-n3" ref="artCatCtrl" label="Tipo" :artCatIdParent="1" v-model="artCat" :readonly="true" :selectOnReadonly="true" @change="artCatChange"></ArtCatFind>
          </v-col>
        </template>
              <!-- @change="cargarArticulosTrazabilidad" -->

        <template v-if="TipoCreacion == 'Articulo'">
          <v-col cols="12" sm="7" md="6" lg="5" xl="4">
            <ArtFind class="mr-2" v-model="art"></ArtFind>
          </v-col>
        </template>

        <v-col class="pl-2" cols="12" sm="5" md="4" lg="3" xl="3">
          <v-btn 
          style="width: 120px"
          color="primary" 
          @click="cargarArticulosTrazabilidad"
          >
            <v-icon
              left
              dark
            >
              mdi-table-arrow-down
            </v-icon>
            Cargar
          </v-btn>

          <v-btn
          class="ml-2"
          style="width: 120px"
          color="success" 
          @click="nuevaTrazabilidad"
          >
            <v-icon
              left
              dark
            >
              mdi-clipboard-outline
            </v-icon>
            Nueva
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            v-model="mdl_table"
            single-select
            item-key="ArtId"
            dense
            :headers="headers"
            :items="itemsArtTraz"
            :items-per-page="-1"
            disable-pagination
            hide-default-footer
            style="overflow: scroll"
            mobile-breakpoint="0"
            @click:row="selTableRowArtTraz"
            class="elevation-1">

            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.ArtTrazCant="{ item }">
              <v-text-field dense v-model="item.ArtTrazCant" :background-color="background_color_cant(item)" type="number" class="pt-0 pb-1 mb-n7" solo flat @change="CantidadCambiada" ></v-text-field>
            </template>

            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.ArtTrazLote="{ item }">
              <v-text-field dense v-model="item.ArtTrazLote" :background-color="background_color_lote(item)" type="text" class="pt-0 pb-1 mb-n7" solo flat @change="LoteCambiado"></v-text-field>
            </template>

            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.ArtTrazCad="{ item }">
              <v-text-field dense v-model="item.ArtTrazCad" :background-color="background_color_cad(item)" type="date" class="pt-0 pb-1 mb-n7" solo flat @change="CaducidadCambiada"></v-text-field>
            </template>

          </v-data-table>          
        </v-col>
      </v-row>

    <!-- <v-row style="height:60px" no-gutters="true">
      <v-col>
      <div class="text-center">
          <v-btn
            color="primary"
            class="mt-4 mr-2"
            @click="grabar"
            style="width:100px"
            ref="grabarBtn"
            >
            Grabar
          </v-btn>
      </div>
      </v-col>
    </v-row> -->

    </v-container>
  <!-- </div> -->
  </v-form>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  // import router from '../router'
  import ArtFind from "../components/ArtFind.vue";
  import ArtCatFind from "../components/ArtCatFind.vue";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz', 'dirId'])
    },
    components: {
      ArtFind,
      ArtCatFind
    },
    data () {
      // var today = new Date();
      // var currentDate = today.getFullYear() +'-'+ String(today.getMonth() + 1).padStart(2, "0") + '-'+ String(today.getDate()).padStart(2, "0");
      var today = new Date();
      var fechaActual = today.getFullYear() +'-'+ String(today.getMonth() + 1).padStart(2, "0") + '-'+ String(today.getDate()).padStart(2, "0");
      var horaActual = String(today.getHours()).padStart(2, "0") + ":" + String(today.getMinutes()).padStart(2, "0");
      return {
        mdl_table: [],
        loading: true,
        valid: false,
        // fecha: currentDate,
        artCat: {},
        headers: [
          { text: 'ARTICULO', value: 'Art.ArtNom', width: "350", class: "col_maq" },
          { text: 'CANTIDAD', value: 'ArtTrazCant', width: "140" },
          { text: 'LOTE', value: 'ArtTrazLote', width: "220" },
          { text: 'CADUCIDAD', value: 'ArtTrazCad', width: "220" },
        ],
        fecha: fechaActual, // new Date().toISOString().substr(0, 10),
        fechaFormateada: this.formatDate(fechaActual), // (new Date().toISOString().substr(0, 10)),
        menu1: false,
        empleado: [],
        // itemsEmpleado: [], 
        itemsArtTraz: [],
        selectedItemArtTraz: {},
        hora: horaActual,
        menu2: false,
        TipoCreacion: "Grupo",
        art: [],
        loteRules: [
        v => (!!v || !!this.itemsArtTraz.ArtTrazCant)  || 'El lote es obligatorio',
        ],
      }
    },
    // props: {
    //   datoProps: String
    // },
    // components:{
    //   pdf
    // },
    watch: {
      fecha () {
        this.fechaFormateada = this.formatDate(this.fecha)
//        this.cargarArticulosTrazabilidad();
      },
    },    
    methods: {
      // ...mapMutations('MensajesRecibidos', ['entidadActualSet']),
      // cargarArticulo() {
      //   alert(this.art.ArtNom);
      //   var ArtObj = { ArtNom: this.art.ArtNom };
      //   ArtObj = this.art;
      //   var ArtTrazObj = { Fecha: "", Art: ArtObj, ArtId: "123", PerId: "", DirId: "456", ArtTrazFUM: "" }
      //   this.itemsArtTraz.push(ArtTrazObj);

      //                   // ArtTrazNew.Fecha = FechaHora;
      //                   // ArtTrazNew.Art = Art;
      //                   // ArtTrazNew.ArtId = Art.ArtId;
      //                   // ArtTrazNew.PerId = PerId;
      //                   // ArtTrazNew.DirId = DirId;
      //                   // ArtTrazNew.ArtTrazFUM = DateTime.Now;

      // },
      row_classes(item) {
        if (item.ArtTrazLote != null && item.ArtTrazLote != "") {
          return "orange"; //can also return multiple classes e.g ["orange","disabled"]
        }
      },
      artCatChange() {

      },
      nuevaTrazabilidad() {
        this.artCat = {};
        this.art = [];
        this.itemsArtTraz = [];
        this.cargarFechaHoraActual();
      },
      cargarFechaHoraActual() {
        var today = new Date();
        var fechaActual = today.getFullYear() +'-'+ String(today.getMonth() + 1).padStart(2, "0") + '-'+ String(today.getDate()).padStart(2, "0");
        var horaActual = String(today.getHours()).padStart(2, "0") + ":" + String(today.getMinutes()).padStart(2, "0");

        this.fecha =  fechaActual;
        this.fechaFormateada = this.formatDate(fechaActual);
        this.hora = horaActual;
      },
      cargarArticulosTrazabilidad(){
          var dateTime = this.fecha + ' ' + this.hora;
          var ArtCatId = 0;
          var ArtId = 0;
          if (this.TipoCreacion == "Grupo") {
            ArtCatId = this.artCat.ArtCatId;
            if (ArtCatId == null) {
              alert("Debe elegir un tipo");
              return
            }
          }
          else if (this.TipoCreacion == "Articulo") {
            ArtId = this.art.ArtId;
            if (ArtId == null) {
              alert("Debe elegir un artículo");
              return;
            }

            var artFound = this.itemsArtTraz.find(a=>a.ArtId === ArtId)
            if (artFound != null) {
              alert("El artículo ya existe");
              this.mdl_table = [];
              this.mdl_table.push(artFound);
              return;
            }
          }

          var controllerParameters = {
                    Action: "GET_ARTICULOS_TRAZABILIDAD",
                    FechaHora: dateTime,
                    EmpId: this.empId,
                    PerId: this.empleado.PerId,
                    DirId: this.dirId,
                    ArtCatId: ArtCatId,
                    ArtId: ArtId,
                    CopTrazAnt: this.$store.state.emp.CopTrazAnt
          } ;

          var AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/artTraz", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {

              if (this.itemsArtTraz.length > 0) {
                if (this.TipoCreacion == "Articulo") {
                  this.itemsArtTraz = this.itemsArtTraz.concat(result.data);
                }
                else {
                  this.itemsArtTraz = result.data;
                }
              }
              else {
                this.itemsArtTraz = result.data;
              }

              if (this.itemsArtTraz != null) {
                if (this.itemsArtTraz[0] != null && this.itemsArtTraz[0].Per != null)
                {
                  this.itemsEmpleado.push(this.itemsArtTraz[0].Per);
                  this.empleado = this.itemsArtTraz[0].Per;
                }
                else
                {
                  this.empleado = {};
                  this.itemsEmpleado = [];
//                  this.$refs.empleadoCtrl.internalSearch = null;
                  this.$refs.form.resetValidation();
                }
              }

              this.itemsArtTraz.forEach(element => {
                if (element.ArtTrazCad != null && element.ArtTrazCad != "") {
                  element.ArtTrazCad = element.ArtTrazCad.substring(0, 10);
                }
              })
          });
      },
      actualizarFechaHora(){
        var fecTraz;
        if (this.fecha == null)
        {
          fecTraz = new Date();
        }
        else
        {
          fecTraz = new Date(this.fecha);
        }
        var today = new Date();
        this.fecha = fecTraz.getFullYear() +'-'+ String(fecTraz.getMonth() + 1).padStart(2, "0") + '-'+ String(fecTraz.getDate()).padStart(2, "0");
        this.hora = String(today.getHours()).padStart(2, "0") + ":" + String(today.getMinutes()).padStart(2, "0");
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`

        // return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      validar(){
        var esValido = this.$refs.form.validate()
        return esValido;
        // if (this.valid)
        // {
        //     return true;
        // }
        // else
        // {
        //   return false;
        // }
      },
      // grabar () {
      //   if (this.validar())
      //   {
      //     var controllerParameters = {
      //               Action: "SAVE_CONTROL_TEMPERATURAS",
      //               Fecha: new Date(this.fecha),
      //               EmpId: this.empId,
      //               ControlNum: this.control.ControlNum,
      //               PerId: this.empleado.PerId,
      //               DirId: this.dirId,
      //               MaqTtraList: this.itemsArtTraz
      //     } ;       

      //     var AuthToken = localStorage.getItem('token');
      //     axios({ method: "POST", "url": this.urlRaiz + "/api/art", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
      //         .then(result => {
      //           if (result.status >= 200 && result.status <= 299){
      //            alert("Trazabilidad grabada con éxito");
      //             router.push('/');
      //           }
      //         })
      //         .catch(error => {
      //             alert(error);
      //         })
      //   }
      // },
      grabarLinea(itemArtTraz) {
        if (this.validar())
        {
          var controllerParameters = {
                    Action: "SAVE_TRAZABILIDAD_LINEA",
                    Fecha: new Date(this.fecha),
                    EmpId: this.empId,
                    PerId: this.empleado.PerId,
                    DirId: this.dirId,
                    ArtTraz: itemArtTraz
          } ;       

          var AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/artTraz", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
              .then(result => {
                if (!(result.status >= 200 && result.status <= 299)){
                 alert("Error en grabación de trazabilidad");
                }
                else{
                  itemArtTraz.ArtTrazId = result.data.ArtTrazId;
                  itemArtTraz.ImpReg3 = result.data.ImpReg3;
                }
              })
              .catch(error => {
                  alert(error);
              })
        }
      },
      background_color_cant(itm){
        if (((itm.ArtTrazLote != null && itm.ArtTrazLote != "") || (itm.ArtTrazCad != null && itm.ArtTrazCad != "")) && (itm.ArtTrazCant == null || itm.ArtTrazCant <= 0)) {
          return "red lighten-4";
        }
        else if ((itm.ArtTrazCant != null && itm.ArtTrazCant > 0) && (itm.ArtTrazLote != null && itm.ArtTrazLote != "") && (itm.ArtTrazCad != null && itm.ArtTrazCad != "")) {
          return "green lighten-4";
        }
      },
      background_color_lote(itm){
        if (((itm.ArtTrazCant != null && itm.ArtTrazCant > 0) || (itm.ArtTrazCad != null && itm.ArtTrazCad != "")) && (itm.ArtTrazLote == null || itm.ArtTrazLote == "")) {
          return "red lighten-4";
        }
        else if ((itm.ArtTrazCant != null && itm.ArtTrazCant > 0) && (itm.ArtTrazLote != null && itm.ArtTrazLote != "") && (itm.ArtTrazCad != null && itm.ArtTrazCad != "")) {
          return "green lighten-4";
        }
      },
      background_color_cad(itm){
        if (((itm.ArtTrazCant != null && itm.ArtTrazCant > 0) || (itm.ArtTrazLote != null && itm.ArtTrazLote != "")) && (itm.ArtTrazCad == null || itm.ArtTrazCad == "")) {
          return "red lighten-4";
        }
        else if ((itm.ArtTrazCant != null && itm.ArtTrazCant > 0) && (itm.ArtTrazLote != null && itm.ArtTrazLote != "") && (itm.ArtTrazCad != null && itm.ArtTrazCad != "")) {
          return "green lighten-4";
        }
      },

      selTableRowArtTraz(item){
        this.selectedItemArtTraz = item;
      },
      CantidadCambiada(){
        this.grabarLinea(this.selectedItemArtTraz);
      },
      LoteCambiado(){
        this.grabarLinea(this.selectedItemArtTraz);
      },
      CaducidadCambiada(){
        this.grabarLinea(this.selectedItemArtTraz);
      }
  },
  mounted(){
    this.art = [];
  }
}
</script>
<style>
  .col_maq {
    min-width: 230px;
  }
</style>